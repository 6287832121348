import React, { Component } from "react";
import Helmet from "react-helmet";

// Common components & Layout
import Layout from "../layouts";

// Individual components that make up the page
import Newsletter from "../components/newsletter/newsletter";

import "../../static/styles/404.css";

class My404 extends Component {
  render() {
    return (
      <Layout>
        <Helmet bodyAttributes={{ class: "404-page" }} />

        <main id="four-o-four">
          <section>
            <div class="container">
              <p>Oh no! Page not found.</p>
              <i class="icon icons face"></i>
              <p>
                This page doesn't exist,
                <br />
                that's a Fact!
              </p>              
            </div>
          </section>
          <Newsletter />
        </main>        
      </Layout>
    );
  }
}

export default My404;
